import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
// import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';

function Result(props) {
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const confetiRef = useRef(null);

  useEffect(() => {
    setHeight(confetiRef.current.clientHeight);
    setWidth(confetiRef.current.clientWidth);
  }, []);

  return (
    <CSSTransitionGroup
      className="container result"
      component="div"
      transitionName="fade"
      transitionEnterTimeout={800}
      transitionLeaveTimeout={500}
      transitionAppear
      transitionAppearTimeout={500}
    >
      <div className='result'>
        <strong>
          {(props.quizResult)==="Gold"? (
            <>
            <div className="confettie-wrap" ref={confetiRef}>
            <Confetti numberOfPieces={150} width={width} height={height} />
            <div className='padding'>
            
            <span>You are selected for the lucky draw!<br></br><br></br>
              {/*<a href='https://forms.gle/EKjkDCXpV8jv71uP7' target='_blank' className='submitButton'>Here</a>*/} Submit Your Details & Our team will get back to you shortly</span> 
             </div>
             </div>
              <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScdka_rciCeQ-ZbmlSOXkGNJI7TGyy6IGRaMFMjsArtUDR0Zg/viewform?embedded=true" width="100%" height="1250" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            
            </>
          ):(
            <div className='extrapadding' ref={confetiRef}>
              <br></br><br></br>
              Sorry! Better Luck Next Time!!
              <br></br><br></br>
              <a href='/' className='submitButton'>Try Agian</a>
              <br></br><br></br>
              </div>
          )
          
          }
        </strong>
      </div>
      {/* <img src={glogin} onClick={signupWithGoogle}  className='glogin' alt='glogin'/> */}
    </CSSTransitionGroup>
  );
}

Result.propTypes = {
  quizResult: PropTypes.string.isRequired
};

export default Result;
