var quizQuestions = [
  {
      question: "Who is the current president of India?",
      answers: [
          {
              type: "Better-Luck-Next-Time",
              content: "Narendra Modi"
          },
          {
              type: "10%-Off",
              content: "Ramnath Kovind"
          },
          {
              type: "Gold",
              content: "Draupadi Murmu"
          }
      ]
  },
  {
      question: "Who is the top goal scorer for Indian national football team?",
      answers: [
          {
              type: "Gold",
              content: "Sunil Chetri"
          },
          {
              type: "Better-Luck-Next-Time",
              content: "Bhaichung Bhutia"
          },
          {
              type: "10%-Off",
              content: "Subrata Bhattacharya"
          }
      ]
  },
  {
      question: "Which is the tallest statue in India?",
      answers: [
          {
              type: "Better-Luck-Next-Time",
              content: "Statue of Liberty"
          },
          {
              type: "Gold",
              content: "Satue of Unity"
          },
          {
              type: "10%-Off",
              content: "Bon Scott Statue"
          }
      ]
  },
  {
      question: "Who wrote the national song of India?",
      answers: [
          {
              type: "10%-Off",
              content: "Rabindranath Tagore"
          },
          {
              type: "Gold",
              content: "Bakimchandra Chattopadhaya"
          },
          {
              type: "Better-Luck-Next-Time",
              content: "Kazi Najrul Islam"
          }
      ]
  },
  {
      question: "How many spokes are there in the wheel of Indian national flag?",
      answers: [
          {
              type: "Better-Luck-Next-Time",
              content: "Eighteen (18)"
          },
          {
              type: "10%-Off",
              content: "Twenty six (26)"
          },
          {
              type: "Gold",
              content: "Twenty four (24)"
          }
      ]
  }
];

export default quizQuestions;
